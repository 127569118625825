<template>
  <CCard>
    <CCardHeader>
      <strong>{{ titulo }}</strong>
    </CCardHeader>
    <CCardBody>
      <CForm :items="categoria">
        <CInput
          id="name"
          description="Nombre de la categoría"
          label="Nombre de la categoría"
          horizontal
          :value="categoria.category_name"
        />
      </CForm>
      <div class="d-flex justify-content-center">
        <CButton
          class="btn btn-secondary"
          @click="volver()"
        >
          Cancelar
        </CButton>
        <CButton
          class="btn btn-success"
          @click="editButton()"
        >
          {{ textBoton }}
        </CButton>
      </div>
      <CRow class="justify-content-center">
        <CCol
          md="9"
          class="p-4"
        >
          <CAlert
            color="danger"
            dismissible
            :show.sync="showParametersError"
            close-button
          >
            <strong>AVISO</strong> Campos vacios o erroneos

            <CProgress
              :max="10"
              :value="showParametersError"
              height="3px"
              color="danger"
              animated
            />
          </CAlert>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import {getCategoriasData} from '../../utils/utils';
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from "jquery";

export default {
  name: "EditarCategoria",
  data() {
    return {
      categoria: {},
      temp: 0,
      tempC: 0,
      categorias: [],
      id: null,
      titulo: "Nueva categoría",
      textBoton: "Crear categoría",
      informacion: false,
      showParametersError: 0,
    };
  },
  created() {
    //Aquí llamamos a los metodos para que se llamen a las funciones y recoger los datos
    //También dependiendo de si uno de los parametros que se pasa por la url, hacemos que sea para editar o de información
    this.id = this.$route.params.id;
    this.getCategorias();
  },
  methods: {
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla
     */
    recibirDatos() {
      if (!this.id)
        return;

      this.titulo = "Editar categoria";
      this.textBoton = "Editar";

      if (this.temp > 0) {
        return this.categoria;
      }
      this.temp++;
    },
    volver() {
      //el botón para volver hacia atrás
      window.history.back();
    },
    /**
     * Funcion encargada de crear o modificar la categoría
     */
    editButton() {
      let category_name = $("#name").val();
      let token = localStorage.token;

      if (!this.id) {
        axios.post(
            process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_CATEGORIAS +
              "create",
            {
              category_name: category_name,
            },
            {
              headers: {
                Authorization: token,
              },
            }
          ).then(() => {
            this.$router.push("/categoria");
          }).catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.showParametersError = 10;
              }
              if (error.response.status == 401) {
                window.location = "#/pages/login";
              }
              if (error.response.status == 404) {
                window.location = "#/404";
              }
              if (error.response.status == 500) {
                window.location = "#/500";
              }
            }
          });

        return;
      }

      axios.patch(
          process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_CATEGORIAS +
            this.id,
          {
            category_name: category_name,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        ).then(() => {
          this.$router.push("/categoria");
        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 400) {
              this.showParametersError = 10;
            }
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/404";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
    },
    /**
     * Función en la que se obtiene y se guarda la información
     * de las categorias en la variable categorías.
     * 
     * @returns {object[]} Un arreglo con las categorias obtenidas
     */
    getCategorias() {
      if (this.tempC > 0) {
        return this.categoria
      }
      let data = getCategoriasData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        for (let index = 0; index < result.length; index++) {
          if(this.id == result[index].category_id) {
            this.categoria = result[index];
            break;
          }
        }

        this.tempC++;
      });
      this.recibirDatos();
      return this.categoria;
    }
  },
};
</script>